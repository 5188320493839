import {
  ChakraStarHalf,
  ChakraStarFull,
  ChakraStarEmpty,
} from "src/lib/utils/icons";

const ReviewStars = ({ rating }) => {
  return (
    <>
      {Array.from(Array(Number(5)), (star, i) => {
        const parsedRating = (Math.round(rating * 2) / 2)
          .toFixed(1)
          .toString()
          .split(".");

        return Number(parsedRating[0]) >= i + 1 ? (
          <ChakraStarFull
            w="32px"
            h="32px"
            color="orange.base"
            key={`full-star-${rating}-${star}-${i}`}
          />
        ) : Number(parsedRating[0]) + 1 === i + 1 &&
          Number(parsedRating[1]) !== 0 ? (
          <ChakraStarHalf
            w="32px"
            h="32px"
            color="orange.base"
            key={`half-star-${rating}-${star}-${i}`}
          />
        ) : (
          <ChakraStarEmpty
            w="32px"
            h="32px"
            color="orange.base"
            key={`empty-star-${rating}-${star}-${i}`}
          />
        );
      })}
    </>
  );
};

export default ReviewStars;
