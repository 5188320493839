import { Progress, HStack, VStack, Text } from "@chakra-ui/react";
import styles from "src/theme/css/progressBar.module.css";

const ProgressBar = ({ header, percentage, loading }) => {
  return (
    <HStack w="95%" justify="space-between">
      <VStack w="100%" align="flex-start">
        <Text size="md" color="navyBlue">
          {header}
        </Text>
        <HStack w="100%" justify="space-between">
          <Progress
            size="md"
            colorScheme="green"
            alignSelf="center"
            value={percentage}
            w="80%"
            className={styles.progressBar}
            isIndeterminate={loading}
          />
          <Text
            size="lg"
            alignSelf="flex-end"
            fontWeight="bold"
            color="navyBlue"
          >
            {loading ? "...%" : `${percentage}%`}
          </Text>
        </HStack>
      </VStack>
    </HStack>
  );
};

export default ProgressBar;
