import { VStack, HStack, Text, Avatar, Spacer } from "@chakra-ui/react";
import ReviewStars from "src/components/pages/user/profile/reviews/ReviewStars";

const UserReview = ({ rating, name, avatarPath, date, content }) => {
  return (
    <VStack
      justify="space-between"
      align="flex-start"
      p="1.5%"
      w="60vw"
      minH="17.5vh"
      border="1px solid"
      borderColor="lightGrey"
      borderRadius="10px"
    >
      <Text size="md">{content}</Text>
      <Text size="xs" color="darkGrey">
        {date}
      </Text>
      <HStack w="100%" spacing={6}>
        <ReviewStars rating={rating} />
        <Spacer />
        <Text size="sm">{name}</Text>
        <Avatar
          size="sm"
          name={name}
          src={
            avatarPath
              ? `https://res.cloudinary.com/seo-for-rei/image/upload/c_thumb,g_face,q_auto,f_auto/${avatarPath}`
              : null
          }
          borderRadius="5px"
          userSelect="none"
        />
      </HStack>
    </VStack>
  );
};

export default UserReview;
