import { VStack, Heading } from "@chakra-ui/react";
import ProgressBar from "src/components/pages/user/dashboard/ProgressBar";

const ReviewScores = ({ headerArr, scoreArr, loading, reviewsNumber }) => {
  return (
    <VStack
      w="100%"
      justify="space-evenly"
      align="flex-start"
      bgColor="#f8f0f2"
      borderRadius="10px"
      p="3%"
    >
      <Heading as="h3" size="md">
        Review Scores
      </Heading>
      <VStack w="100%" spacing={9}>
        <ProgressBar
          loading={loading}
          header={`Trust (based on ${
            loading ? "..." : reviewsNumber ? reviewsNumber : "0"
          } reviews)`}
          percentage={scoreArr[0] ? scoreArr[0] : "N/A"}
        />
        {/* TODO: put 'ratings' variable length here */}
        <ProgressBar
          header={`Reliability (based on ${3} ratings)`}
          percentage={scoreArr[1] ? scoreArr[1] : "N/A"}
        />
      </VStack>
    </VStack>
  );
};

export default ReviewScores;
