export const defaultLoader = ({ src, width, quality }) => {
  const widthParams = width ? `w_${width},` : "";
  const qualityParams = quality ? `q_${quality},f_auto/` : "q_auto,f_auto/";

  return `https://res.cloudinary.com/${process.env.NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME}/image/upload/${widthParams}${qualityParams}${src}`;
};

export const fullFaceLoader = ({ src, width, quality }) => {
  const widthParams = width ? `w_${width},` : "";
  const qualityParams = quality ? `q_${quality},f_auto/` : "q_auto,f_auto/";

  return `https://res.cloudinary.com/${process.env.NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME}/image/upload/c_fill,g_face,${widthParams}${qualityParams}${src}`;
};

export const thumbnailFaceLoader = ({ src, width, quality }) => {
  const widthParams = width ? `w_${width},` : "";
  const qualityParams = quality ? `q_${quality},f_auto/` : "q_auto,f_auto/";

  return `https://res.cloudinary.com/${process.env.NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME}/image/upload/c_thumb,g_face,${widthParams}${qualityParams}${src}`;
};

export const stateLoader = ({ src, width, quality }) => {
  const widthParams = width ? `w_${width},` : "";
  const qualityParams = quality ? `q_${quality},f_auto/` : "q_auto,f_auto/";

  return `https://res.cloudinary.com/${process.env.NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME}/image/upload/${widthParams}${qualityParams}${src}`;
};
